import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import DestinationAddress from "../../models/DestinationAddress";
import OriginationAddress from "../../models/OriginationAddress";
import Shipment from "../../models/Shipment";
import { SET_ORIGINATION_ADDRESS } from "../../redux/constants/shipmentAddressConstants/ShipmentAddress.Constants";
import { SET_SHIPMENT } from "../../redux/constants/shipmentConstants/Shipment.constants";
import { ReduxState } from "../../redux/reducers/_rootReducer/_ReduxState";
import DestinationAddressInput from "./DestinationAddressInput";
import OriginationAddressInput from "./OriginationAddressInput";
import { iReviewInfo } from "../../models/IReviewInfo";
import "../App.css";
import { usePageValidation } from "../../components/customHooks/usePageValidation";

function ShipmentAddressInfoPage() {
    const shipment: Shipment = useSelector((store: ReduxState) => store.ShipmentReducer);
    const reviewInfo: iReviewInfo = useSelector((store: ReduxState) => store.ReviewReducer);
    const originationAddress: OriginationAddress = useSelector((store: ReduxState) => store.OriginationAddressReducer);
    const destinationAddress: DestinationAddress = useSelector((store: ReduxState) => store.DestinationAddressReducer);
    const [preventContinue, setPreventContinue] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function isNullEmptyOrUndefined(str: string | null): boolean {
        return !str || str === "";
    }

    useEffect(() => {
        dispatch({
            type: SET_ORIGINATION_ADDRESS,
            payload: {
                name: shipment.originName ?? "",
                address1: shipment.originAddress1 ?? "",
                address2: shipment.originAddress2 ?? "",
                city: shipment.originCity ?? "",
                state: shipment.originState ?? "",
                zip: shipment.originZip ?? "",
                comments: shipment.originComments ?? "",
                pickupWindowStart: shipment.pickupDateWindowStart ?? null,
                dockOpen: shipment.dockOpen ?? null,
                dockClose: shipment.dockClose ?? null,
            },
        });
    }, [dispatch, shipment]);

    useEffect(() => {
        const validOrigination = !(
            (isNullEmptyOrUndefined(originationAddress.name) && isNullEmptyOrUndefined(shipment.originName)) ||
            (isNullEmptyOrUndefined(originationAddress.address1) && isNullEmptyOrUndefined(shipment.originAddress1)) ||
            (isNullEmptyOrUndefined(originationAddress.zip) && isNullEmptyOrUndefined(shipment.originZip)) ||
            (isNullEmptyOrUndefined(originationAddress.city) && isNullEmptyOrUndefined(shipment.originCity)) ||
            originationAddress.pickupWindowStart?.toString() === "1970-01-01T00:00:00Z" ||
            originationAddress.dockClose?.toString() === "1970-01-01T00:00:00Z" ||
            originationAddress.dockOpen?.toString() === "1970-01-01T00:00:00Z"
        );

        const validDestinationAddress = !(
            (isNullEmptyOrUndefined(destinationAddress.name) && isNullEmptyOrUndefined(shipment.destinationName)) ||
            (isNullEmptyOrUndefined(destinationAddress.address1) && isNullEmptyOrUndefined(shipment.destinationAddress1)) ||
            (isNullEmptyOrUndefined(destinationAddress.zip) && isNullEmptyOrUndefined(shipment.destinationZip)) ||
            (isNullEmptyOrUndefined(destinationAddress.city) && isNullEmptyOrUndefined(shipment.destinationCity))
        );

        if (validOrigination && validDestinationAddress) {
            setPreventContinue(false);
        } else {
            setPreventContinue(true);
        }
    }, [originationAddress, destinationAddress, shipment]);

    function continueProcess(): void {
        const updatedShipment: Shipment = {
            id: shipment.id,
            primaryVendorContactName: shipment.primaryVendorContactName,
            primaryVendorContactPhone: shipment.primaryVendorContactPhone,
            primaryVendorContactEmail: shipment.primaryVendorContactEmail,
            rocketShippingLoadId: 0,
            createDate: shipment.createDate,
            dockOpen: originationAddress.dockOpen ?? new Date(),
            dockClose: originationAddress.dockClose ?? new Date(),
            statusId: shipment.statusId,
            numCartonsWithLengthOver48Inches: shipment.numCartonsWithLengthOver48Inches,
            shipmentBatchId: 0,
            totalCartons: shipment.totalCartons,
            totalWeight: shipment.totalWeight,
            pickupDateWindowStart: originationAddress.pickupWindowStart ?? shipment.pickupDateWindowStart,
            originName: originationAddress.name,
            originAddress1: originationAddress.address1,
            originAddress2: originationAddress.address2 ?? "",
            originCity: originationAddress.city,
            originState: originationAddress.state,
            originZip: originationAddress.zip,
            originComments: originationAddress.comments ?? shipment.originComments,
            destinationName: destinationAddress.name,
            destinationAddress1: destinationAddress.address1,
            destinationAddress2: destinationAddress.address2 ?? "",
            destinationCity: destinationAddress.city,
            destinationState: destinationAddress.state,
            destinationZip: destinationAddress.zip,
        };
        dispatch({ type: SET_SHIPMENT, payload: updatedShipment });

        if (reviewInfo.inReview) {
            navigate("/ShipmentReviewPage");
        } else {
            navigate("/ShipmentInformationPage");
        }
    }

    function GoBack() {
        navigate("/PoValidationPage");
    }

    usePageValidation();

    return (
        <>
            <div className="col-12" style={{ display: "flex" }}>
                <OriginationAddressInput />
                <DestinationAddressInput />
                <Button
                    color="success"
                    disabled={preventContinue}
                    style={{
                        position: "absolute",
                        bottom: "-3em",
                        right: "1.75em",
                    }}
                    onClick={() => continueProcess()}
                >
                    Continue
                </Button>
            </div>
            <div className="go-back-btn">
                <Button onClick={() => GoBack()}>Go Back</Button>
            </div>
        </>
    );
}

export default ShipmentAddressInfoPage;
